<template>
  <section class="container h-screen mx-auto flex">
    <div class="flex-grow flex flex-col max-w-xl justify-center p-6">
      <h1 class="text-6xl font-bold">Sign Up</h1>
      <p class="mt-4">
        Already have an account?
        <span class="cursor-pointer underline">
          <router-link to="/login"> Login </router-link>
        </span>
      </p>
      <form @submit="handleSignUp">

        <div class="flex">
          <div class="w-1/2 pr-1">
            <label class="block mt-6 mb-1">First Name</label>
            <input
              v-model="firstname"
              class="w-full p-4 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 outline-none"
              type="text"
            />
          </div>
          <div class="w-1/2 pl-1">
            <label class="block mt-6 mb-1">Last Name</label>
            <input
              v-model="lastname"
              class="w-full p-4 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 outline-none"
              type="text"
            />
          </div>
        </div>

        <label class="block mt-6 mb-1"> Email</label>
        <input
          v-model="email"
          class="w-full p-4 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 outline-none"
          type="text"
        />
        <label class="block mt-6 mb-1"> Password</label>
        <input
          v-model="password"
          class="w-full p-4 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 outline-none"
          type="password"
        />

        <div class="mt-6">
          <button
            :disabled="!email || !password || !firstname || !lastname"
            type="submit"
            class="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>

  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SignUp',
  data () {
    return {
      email: '',
      password: '',
      firstname: '',
      lastname: ''
    }
  },
  methods: {
    ...mapActions(['signup']),
    handleSignUp (e) {
      e.preventDefault()
      this.signup({
        email: this.email,
        password: this.password,
        name: this.firstname + ' ' + this.lastname
      })
    }
  }
}
</script>
